@import '../base/variables';

.workshop-label{
    cursor: pointer;
}

.workshop-item{

    display: flex;
    flex-basis: calc(50% - 2rem);
    flex-direction: column;
    border-radius: $border-radius;
    border: 1px solid #CCC;
    background: #FFF;
    padding: $item-padding;

    position: relative;
    min-height: 14rem;

    &--selected{
        display: flex;
        flex-basis: calc(50% - 2rem);
        flex-direction: column;
        border-radius: $border-radius;
        border: 2px solid $light-blue;
        background: #FFF;
        padding: 1rem;

        position: relative;
        min-height: 14rem;

        .workshop-item__heading--name,
        .workshop-item__description {
            color: $light-blue; 
        }
    
    }

    &__heading{

        display: flex;
        flex-direction: row;
        gap: .5rem;

        &--name{
            color: $dark-blue;
            font-size: 1.5rem;
            line-height: $line-height;
        }

        &--radio{
                
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 0.75rem;
            border: 1px solid $light-gray;
            background: #FFF;
            cursor: pointer;

          
        }
    }


    &__description{
        color: $paragraph-gray;
        font-size: 1.2rem;
        line-height: $line-height;
        margin-top: 1rem;
    }

    &__image{
        display: flex;
        align-self: flex-end;
        justify-content: center;
        align-items: center;
        width: 8.5rem;
        height: 7.5rem;
        border-radius: 3rem;

        position: absolute;
        bottom: 0;
        
        
        &-img{
            display: flex;
            width: 4.375rem;
            height: 4.375rem;
        }
        
    }
}

.blue{
    background: rgba(224, 239, 255, 0.5);

}

.red{
    background: rgba(255, 229, 234, 0.5);
}