@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&family=Montserrat:wght@400;700&display=swap");
*,
*::after,
*::before,
#root {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Quicksand", sans-serif;
}

.app {
  min-height: 100vh;
}

.subtotal {
  display: flex;
  min-width: 15rem;
  background-color: #14253F;
  flex-direction: column;
  padding: 0.5rem 3.5rem;
}
@media (max-width: 480px) {
  .subtotal {
    flex-grow: 1;
  }
}
.subtotal__currency {
  color: #FFFFFF;
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 700;
}
.subtotal__amount {
  color: #FFFFFF;
  font-size: 2.8rem;
  font-weight: 700;
  text-align: center;
  line-height: 2.6rem;
}
.subtotal__label {
  color: #C2CAD4;
  font-size: 1rem;
  text-align: center;
}

.workshop-label {
  cursor: pointer;
}

.workshop-item {
  display: flex;
  flex-basis: calc(50% - 2rem);
  flex-direction: column;
  border-radius: 0.62rem;
  border: 1px solid #CCC;
  background: #FFF;
  padding: 1.25rem;
  position: relative;
  min-height: 14rem;
}
.workshop-item--selected {
  display: flex;
  flex-basis: calc(50% - 2rem);
  flex-direction: column;
  border-radius: 0.62rem;
  border: 2px solid #0059FC;
  background: #FFF;
  padding: 1rem;
  position: relative;
  min-height: 14rem;
}
.workshop-item--selected .workshop-item__heading--name,
.workshop-item--selected .workshop-item__description {
  color: #0059FC;
}
.workshop-item__heading {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
.workshop-item__heading--name {
  color: #14253F;
  font-size: 1.5rem;
  line-height: 1.5rem;
}
.workshop-item__heading--radio {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.75rem;
  border: 1px solid #70829A;
  background: #FFF;
  cursor: pointer;
}
.workshop-item__description {
  color: #334D6E;
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-top: 1rem;
}
.workshop-item__image {
  display: flex;
  align-self: flex-end;
  justify-content: center;
  align-items: center;
  width: 8.5rem;
  height: 7.5rem;
  border-radius: 3rem;
  position: absolute;
  bottom: 0;
}
.workshop-item__image-img {
  display: flex;
  width: 4.375rem;
  height: 4.375rem;
}

.blue {
  background: rgba(224, 239, 255, 0.5);
}

.red {
  background: rgba(255, 229, 234, 0.5);
}

.calendar {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #fff;
  border-radius: 8px;
  max-width: 90rem;
  margin: 20px auto;
  overflow: hidden;
}
.calendar__days-of-week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-top: 2rem;
  text-align: center;
}
.calendar__days-of-week--day {
  color: #70829A;
  font-weight: 400;
  font-size: 1rem;
}
.calendar__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem;
  font-size: 1.25rem;
}
.calendar__header__button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.calendar__header__button.disabled {
  color: #70829A;
  cursor: not-allowed;
}
.calendar__header__button.disabled svg {
  fill: #70829A;
}
.calendar__header__button.enabled {
  color: #0059FC;
  cursor: pointer;
}
.calendar__header__button.enabled svg {
  fill: #0059FC;
}
.calendar__header__month {
  color: #70829A;
  font-size: 1.25rem;
}
.calendar__header__month-year {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.5em;
  font-weight: 400;
  color: #14253F;
  line-height: 1.5rem;
}
.calendar__header__year {
  font-size: 1rem;
  align-self: center;
}
.calendar__grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  gap: 0.5rem;
  padding: 1rem;
}
.calendar__grid .day {
  border: 1px solid #70829A;
  padding: 1rem;
  border-radius: 0.62rem;
  cursor: pointer;
}
.calendar__grid .day.selected {
  background-color: #0059FC;
}
.calendar__grid .day.selected .day__date, .calendar__grid .day.selected .day__price {
  color: #FFFFFF;
}
.calendar__grid .day.unavailable {
  color: #C2CAD4;
  border: 1px solid #C2CAD4;
}
.calendar__grid .day.unavailable .day__date, .calendar__grid .day.unavailable .day__price {
  color: #C2CAD4;
  cursor: default;
  pointer-events: none;
}
.calendar__grid .day:hover {
  background-color: #0059FC;
}
.calendar__grid .day:hover .day__date, .calendar__grid .day:hover .day__price {
  color: #FFFFFF;
}
.calendar__grid .day.other-month {
  color: #aaa;
}
.calendar__grid .day__date {
  font-weight: 400;
  color: #14253F;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-transform: uppercase;
}
.calendar__grid .day__price {
  margin-top: 0.5rem;
  color: #0059FC;
  font-size: 1rem;
  font-weight: bold;
}
@media (max-width: 768px) {
  .calendar__grid {
    grid-template-columns: repeat(7, 1fr);
  }
  .calendar__grid .day {
    height: 50px;
    width: 50px;
    padding: 0.5rem;
  }
  .calendar__header {
    font-size: 1rem;
    padding: 1rem 0.5rem;
    justify-content: space-around;
  }
  .calendar__header__month-year {
    font-size: 1.2em;
  }
  .calendar__header__month {
    font-size: 1rem;
  }
  .calendar__days-of-week {
    font-size: 0.8rem;
  }
}
.calendar .calendar__grid .unavailable .day__price {
  font-size: 0.7rem;
}

.book-option {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 0.62rem;
  border: 1px solid #CCC;
  background: #FFF;
  padding: 1.25rem;
  flex-grow: 1;
}
.book-option__label {
  display: flex;
}
.book-option__heading {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
.book-option__heading--input {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.75rem;
  border: 1px solid #70829A;
  background: #FFF;
  cursor: pointer;
}
.book-option__heading--text {
  color: #14253F;
  font-size: 1.7rem;
  line-height: 1.5rem;
  font-weight: 600;
}
.book-option__heading--price {
  margin-left: 0.5rem;
}
.book-option__discount {
  position: absolute;
  left: 78%;
  display: inline-flex;
  height: 1.5rem;
  padding: 0.125rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  border-radius: 3.125rem;
  color: #00A729;
  font-size: 1rem;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  background: rgba(0, 167, 41, 0.12);
}
.book-option__description {
  display: flex;
  position: relative;
  flex-direction: column;
  padding-left: 1.8rem;
  padding-top: 0.5rem;
}
.book-option__description--cancel {
  color: #99A6B7;
  font-size: 1rem;
}
.book-option__description--desc {
  padding-top: 2rem;
  color: #334D6E;
  line-height: 1.5rem;
  font-weight: 400;
  font-size: 1.25rem;
}
.book-option__description--desc a {
  color: #334D6E;
}
.book-option__description--tooltip {
  display: flex;
  padding-top: 2rem;
}
.book-option__description--tooltip--checkbox {
  margin-right: 0.5rem;
}
.book-option__description--tooltip p {
  color: #14253F;
  font-size: 1rem;
  font-weight: 500;
}
.book-option__description--tooltip--text {
  visibility: hidden;
  display: flex;
  flex-direction: column;
  max-width: 15rem;
  background-color: #14253F;
  color: #FFFFFF;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  align-items: center;
  position: absolute;
  z-index: 1;
  left: 20%;
  bottom: -12%;
}
.book-option__description--tooltip--text--link {
  color: #FFFFFF;
  font-size: 1rem;
}
.book-option__description--tooltip:hover .book-option__description--tooltip--text {
  visibility: visible;
}

.selected {
  border: 2px solid #0059FC;
}

.registration-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: 50rem;
}
.registration-form__label {
  color: #14253F;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
}
.registration-form__label--checkbox {
  display: flex;
  color: #70829A;
  font-weight: 500;
  font-size: 1.25rem;
}
.registration-form__input {
  display: flex;
  width: 100%;
  height: 4rem;
  border-radius: 0.25rem;
  border: 1px solid #99A6B7;
  padding: 1rem;
  background: #FFFFFF;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: #14253F;
  outline: none;
}
.registration-form__input:focus {
  border: 2px solid #14253F;
}
.registration-form__input::placeholder {
  color: #99A6B7;
  font-size: 1.25rem;
}
.registration-form__input--checkbox {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  border: 1px solid #99A6B7;
  background: #FFF;
  cursor: pointer;
}
.registration-form__input--terms {
  color: #0059FC;
  padding: 0rem 0.5rem;
}
.registration-form__button {
  display: none;
}

@media (max-width: 480px) {
  .registration-form {
    min-width: 0;
  }
}
/*.flag-dropdown{

    background-color: transparent;
    border: none;
}

.react-tel-input .form-control{

width:100%
    border: 1px solid $grey;
}

.react-tel-input .selected-flag .arrow {


}*/
.booking-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 8rem;
}
.booking-details__heading {
  color: #14253F;
  font-size: 2rem;
  font-weight: 600;
  margin: 2rem 0rem;
}
.booking-details__summary {
  padding: 2rem 1.5rem;
  border-radius: 0.625rem;
  background: #F4F7F9;
  min-width: 50rem;
}
.booking-details__summary--item {
  display: flex;
  justify-content: space-between;
  gap: 25rem;
}
.booking-details__summary--description {
  color: #14253F;
  font-size: 1.5rem;
  font-weight: 600;
}
.booking-details__summary--price {
  color: #14253F;
  font-size: 1.5rem;
  font-weight: 400;
}
.booking-details__summary--label {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.5rem;
}
.booking-details__summary--amount {
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 1.5rem;
}
.booking-details__personal {
  padding: 2rem 1.5rem;
  border-radius: 0.625rem;
  background: #F4F7F9;
  min-width: 50rem;
  display: flex;
  flex-direction: column;
}
.booking-details__personal--item {
  font-size: 1.5rem;
  color: #14253F;
  font-weight: 400;
  line-height: 1.5rem;
  padding: 2rem;
  border-bottom: 1px solid rgba(188, 194, 200, 0.5);
}
.booking-details__personal--item:last-child {
  border-bottom: none;
  padding-bottom: 1rem;
}
.booking-details__personal--item:first-child {
  padding-top: 1rem;
}
.booking-details__date {
  margin: 1rem 0rem;
  color: #0059FC;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5rem;
  border-bottom: 1px solid rgb(188, 194, 200);
  padding-bottom: 1.4rem;
}
.booking-details__total--label {
  color: #14253F;
  font-size: 1.25rem;
  font-weight: 600;
}
.booking-details__total--price {
  color: #14253F;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5rem;
}

@media (max-width: 480px) {
  .summary-page {
    min-height: 100vh;
  }
  .booking-details {
    margin-bottom: 1rem;
  }
  .booking-details__heading__text {
    margin: 1rem;
  }
  .booking-details__summary {
    min-width: 0;
    width: 90%;
  }
  .booking-details__summary--item {
    gap: 0;
  }
  .booking-details__personal {
    min-width: 90%;
  }
}
.category-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(356px, 1fr));
  gap: 4rem;
  padding: 2rem;
  list-style: none;
}
@media (max-width: 768px) {
  .category-grid {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
}
.category-grid__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s;
}
.category-grid__item:hover {
  transform: translateY(-5px);
}
.category-grid__item:hover::after {
  content: "→";
  position: absolute;
  right: 10px;
  top: calc(100% - 20px);
  font-size: 24px;
  color: #0059FC;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.category-grid__item:hover .category-grid__name {
  color: #0059FC;
}
.category-grid__image {
  width: 100%;
  height: auto;
}
.category-grid__name {
  align-self: flex-start;
  font-family: "quicksand", sans-serif;
  padding-top: 1rem;
  font-size: 2.2rem;
  text-align: left;
  color: #333;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: color 0.3s ease-in-out;
  position: relative;
}

.workshop-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(240px, 1fr));
  gap: 4rem;
  padding: 2rem;
  list-style: none;
}
@media (max-width: 768px) {
  .workshop-grid {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
}
.workshop-grid__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.2s;
  cursor: pointer;
}
.workshop-grid__item:hover {
  transform: translateY(-5px);
}
.workshop-grid__item:hover::after {
  content: "→";
  position: absolute;
  right: 10px;
  top: calc(100% - 20px);
  font-size: 24px;
  color: #0059FC;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.workshop-grid__item:hover .workshop-grid__item-name {
  color: #0059FC;
}
.workshop-grid__item-image {
  width: 100%;
  height: auto;
}
.workshop-grid__item-name {
  padding-top: 10px;
  font-size: 16px;
  text-align: left;
  font-family: "quicksand", sans-serif;
  color: #333;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.header__close-button {
  display: flex;
  cursor: pointer;
  min-width: 5rem;
  padding: 1.5rem;
  align-items: center;
  justify-content: center;
  border: transparent;
  border-right: 1px solid #E8E9EB;
  border-bottom: 1px solid #E8E9EB;
  background: #FFFFFF;
  font-size: 2rem;
}
@media (max-width: 480px) {
  .header__close-button {
    min-width: 5rem;
  }
}

.progress-indicator {
  display: flex;
  flex-grow: 1;
}
@media (max-width: 480px) {
  .progress-indicator {
    display: none;
  }
}

.step {
  display: flex;
  min-width: 20rem;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 1.5rem 4.375rem;
  flex-direction: row;
}
.step__wrapper {
  display: flex;
}
.step-label {
  color: #14253F;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
}
.step-icon {
  margin-right: 1rem;
  color: #0059FC;
  font-size: 1.25rem;
}
.step-chosen-option {
  font-size: 1rem;
}

.active {
  border-bottom: 3px solid #0059FC;
  flex-direction: column;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
  font-family: "Quicksand", sans-serif;
  gap: 3rem;
  background: rgba(255, 255, 255, 0.01);
  box-shadow: 8px 0px 20px 0px rgba(0, 0, 0, 0.04);
  padding: 1.5rem 0;
  position: fixed;
  bottom: 0;
}
.footer__step-text {
  font-size: 1.25rem;
  color: #334D6E;
  font-weight: 600;
  line-height: 1.5rem;
  text-transform: capitalize;
  font-family: "Quicksand", sans-serif;
}
.footer__previous-step-button {
  border-radius: 0.5rem;
  background: #FFFFFF;
  color: #14253F;
  gap: 0.5rem;
  padding: 0.85rem 1.25rem;
  border: 1px solid #99A6B7;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
  font-family: "Quicksand", sans-serif;
  cursor: pointer;
}
.footer__next-step-button {
  border-radius: 0.5rem;
  background: #0059FC;
  color: #FFFFFF;
  padding: 0.85rem 1.25rem;
  border: transparent;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 400;
  font-family: "Quicksand", sans-serif;
  cursor: pointer;
}

@media (max-width: 480px) {
  .footer {
    gap: 1.5rem;
  }
}
.workshop-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  max-width: 90rem;
  margin: 0 auto;
  padding: 2rem;
  justify-items: center;
  align-content: start;
}
.workshop-list__button {
  display: none;
  grid-column: 1/-1;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 0.5rem;
  background: rgba(0, 89, 252, 0.08);
  color: #0059FC;
  padding: 0.625rem 1rem;
  border: transparent;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  line-height: 1.5rem;
  font-size: 1.25rem;
}

@media (max-width: 768px) {
  .workshop-list {
    grid-template-columns: 1fr;
    padding: 1rem;
  }
}
.heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8rem;
  margin-bottom: 5rem;
}
.heading__text {
  font-size: 3rem;
  color: #14253F;
  font-weight: 700;
}
.heading__subtext {
  font-size: 1.5rem;
  color: #70829A;
  font-weight: 400;
  line-height: 1.5rem;
  margin-top: 0.5rem;
}

.choose-workshop-page {
  display: flex;
  flex-direction: column;
}

@media (max-width: 480px) {
  .heading {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .choose-workshop-page {
    min-height: 100vh;
  }
}
.book-options-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.book-options-page-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  max-width: 90rem;
  margin: 0 auto;
}

.booking-info {
  margin-top: 20px;
  padding: 10px 0;
  text-align: center;
  max-width: 90rem;
}
.booking-info p {
  margin: 0;
  font-size: 14px;
  line-height: 1.5rem;
  color: #333;
}

@media (max-width: 480px) {
  .book-options-page-wrapper {
    grid-template-columns: 1fr;
    margin: 1rem;
    max-width: none;
  }
  .heading__subtext {
    margin: 1rem;
  }
}
.book-date-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.book-date-page .heading {
  margin-bottom: 2rem;
}
.book-date-page__calendar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.personal-data-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.verification-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.verification-page__timer {
  color: #0059FC;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-transform: capitalize;
  margin-top: 5rem;
  margin-bottom: 2rem;
}
.verification-page__button {
  min-width: 30rem;
  min-height: 4rem;
  cursor: pointer;
  border-radius: 0.5rem;
  background: #0059FC;
  color: #FFFFFF;
  padding: 0.75rem 1.5rem;
  border: transparent;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
  font-family: "Quicksand", sans-serif;
}

.success-page {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.success-page__btn {
  display: flex;
  border-radius: 0.5rem;
  background: #0059FC;
  margin: 0 auto;
  color: #FFFFFF;
  cursor: pointer;
  padding: 1rem;
  border: transparent;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 400;
  font-family: "Quicksand", sans-serif;
}

.header-workshops {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid #D7DBE2;
  box-shadow: 9px 9px 19px 0px rgba(155, 163, 178, 0.07);
}
.header-workshops img {
  cursor: pointer;
}

.content {
  margin-top: 8rem;
  padding: 0 8rem;
  margin-bottom: 12rem;
}
@media (max-width: 768px) {
  .content {
    margin-top: 5rem;
    padding: 0 1rem;
  }
}
.content .title {
  font-size: 3.8rem;
  margin-bottom: 5rem;
  color: #14253F;
}
@media (max-width: 768px) {
  .content .title {
    font-size: 3rem;
    padding-left: 2.5rem;
    margin-bottom: 3rem;
  }
}

