@import '../base/variables';
@import '../base/mixins';

.workshop-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    max-width: 90rem;
    margin: 0 auto;
    padding: 2rem;
    justify-items: center;
    align-content: start;

    &__button {
        //temp
        display: none;
       grid-column: 1 / -1;
       //display: flex;
       cursor: pointer; 
       justify-content: center;
       align-items: center;
       gap: 1rem;
       border-radius: .5rem;
       background: rgba(0, 89, 252, 0.08);
       color: $light-blue; 
       padding: 0.625rem 1rem;
       border: transparent;
       font-family: $secondary-font;
       font-weight: 500; 
       line-height: $line-height;
       font-size: $title-size;
       
    }


}


@include respond-to('tablet') {
    .workshop-list {
        grid-template-columns: 1fr; 
        padding: 1rem; 
    }
}
 
